<template>
    <section class="view delivery-methods">
        <view-title v-bind:title="$t('message.delivery-methods.title')"></view-title>
        <div class="container py-5">
            <div class="row">
                <div class="col-md-3">
                    <ul class="nav flex-column position-sticky mb-4">
                        <li class="nav-item"
                            v-for="deliveryClass in deliveryClasses"
                            v-bind:key="deliveryClass._id">
                            <a class="nav-link"
                               v-bind:href="`#${ deliveryClass._id }`">
                                {{ deliveryClass.title[$i18n.locale] }}
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-9">
                    <p class="text-justify">
                        {{ $t("message.delivery-methods.delivery-cat-1") }}{{ deliveryClasses.length }}{{ $t("message.delivery-methods.delivery-cat-2") }}
                    </p>

                    <article v-for="deliveryClass in deliveryClasses"
                             v-bind:key="deliveryClass._id">>
                        <h5 v-bind:id="deliveryClass._id">
                            {{ deliveryClass.title[$i18n.locale] }}
                        </h5>
                        <table class="table">
                            <thead>
                            <tr>
                                <th scope="col">
                                    {{ $t("message.delivery-methods.delivery-method") }}
                                </th>
                                <th scope="col">
                                        <span v-if="deliveryClass.conditions[0].sign === '>' && deliveryClass.conditions[0].includeEqual">
                                            {{ $t("message.delivery-methods.purchase-condition-larger-equal") }}{{ deliveryClass.conditions[0].value }}
                                        </span>
                                    <span v-if="deliveryClass.conditions[0].sign === '>' && !deliveryClass.conditions[0].includeEqual">
                                            {{ $t("message.delivery-methods.purchase-condition-larger-1") }}{{ deliveryClass.conditions[0].value }}{{ $t("message.delivery-methods.purchase-condition-larger-2") }}
                                        </span>
                                </th>
                                <th scope="col">
                                        <span v-if="deliveryClass.conditions[0].sign === '>' && deliveryClass.conditions[0].includeEqual">
                                            {{ $t("message.delivery-methods.purchase-condition-free-larger-equal-1") }}{{ deliveryClass.conditions[0].value }}{{ $t("message.delivery-methods.purchase-condition-free-larger-equal-2") }}
                                        </span>
                                    <span v-if="deliveryClass.conditions[0].sign === '>' && !deliveryClass.conditions[0].includeEqual">
                                            {{ $t("message.delivery-methods.purchase-condition-free-larger") }}{{ deliveryClass.conditions[0].value }}
                                        </span>
                                </th>
                                <th scope="col">
                                    {{ $t("message.delivery-methods.delivery-time") }}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="method in deliveryClass.methods"
                                v-bind:key="method.name[$i18n.locale]">>
                                <td>{{ method.name[$i18n.locale] }}</td>
                                <td>
                                    <span>HK${{ method.price.value }}</span>
                                </td>
                                <td>{{ $t("message.delivery-methods.free") }}</td>
                                <td>{{ method.deliveryTime.from }}-{{ method.deliveryTime.to }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </article>
                </div>

                <!-- <div class="col-md-9"
                     v-if="$i18n.locale === 'zh-hk'">
                    <p class="text-justify">
                        marierskincare.com提供全球送貨服務。收貨國家/地區為5類。
                    </p>

                    <h5 id="hongkong">收貨地區︰香港</h5>
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col">送貨方式</th>
                            <th scope="col">購物少於港幣$1500</th>
                            <th scope="col">購物高於港幣$1500</th>
                            <th scope="col">運送時間（工作日）</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>速遞或郵遞</td>
                            <td>HK$80</td>
                            <td>免費</td>
                            <td>2-5</td>
                        </tr>
                        </tbody>
                    </table>

                    <h5 id="china-macau-taiwan">收貨國家︰中國、澳門及台灣</h5>
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col">送貨方式</th>
                            <th scope="col">購物少於港幣$5000</th>
                            <th scope="col">購物高於港幣$5000</th>
                            <th scope="col">運送時間（工作日）</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>速遞或郵遞</td>
                            <td>HK$100</td>
                            <td>免費</td>
                            <td>3-14</td>
                        </tr>
                        </tbody>
                    </table>

                    <h5 id="sea">收貨國家︰東南亞地區</h5>
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col">送貨方式</th>
                            <th scope="col">購物少於港幣$5000</th>
                            <th scope="col">購物高於港幣$5000</th>
                            <th scope="col">運送時間（工作日）</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>速遞或郵遞</td>
                            <td>HK$200</td>
                            <td>免費</td>
                            <td>14-21</td>
                        </tr>
                        </tbody>
                    </table>

                    <h5 id="australia-new-zealand-japan">收貨國家︰澳洲、新西兰和日本</h5>
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col">送貨方式</th>
                            <th scope="col">購物少於港幣$5000</th>
                            <th scope="col">購物高於港幣$5000</th>
                            <th scope="col">運送時間（工作日）</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>速遞或郵遞</td>
                            <td>HK$300</td>
                            <td>免費</td>
                            <td>8-18</td>
                        </tr>
                        </tbody>
                    </table>

                    <h5 id="usa">收貨國家︰美國</h5>
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col">送貨方式</th>
                            <th scope="col">購物少於港幣$5000</th>
                            <th scope="col">購物高於港幣$5000</th>
                            <th scope="col">運送時間（工作日）</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>速遞或郵遞</td>
                            <td>HK$350</td>
                            <td>免費</td>
                            <td>14-21</td>
                        </tr>
                        </tbody>
                    </table>
                    rest-of-the-world

                    <h5 id="rest-of-the-world">收貨國家︰世界其他地區</h5>
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col">送貨方式</th>
                            <th scope="col">購物少於港幣$5000</th>
                            <th scope="col">購物高於港幣$5000</th>
                            <th scope="col">運送時間（工作日）</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>速遞或郵遞</td>
                            <td>HK$350</td>
                            <td>免費</td>
                            <td>14-21</td>
                        </tr>
                        </tbody>
                    </table>
                </div>  -->
            </div>
        </div>
    </section>
</template>

<script>
    import ViewTitle from "../components/ViewTitle";
    export default {
        name: "DeliveryMethods",
        components: {
            ViewTitle
        },
        computed: {
            deliveryClasses () {
                return this.$store.state.mall.shop.deliveryClasses;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .delivery-methods {
        color: rgba(113, 113, 113, 1);

        ul.nav {
            top: 65px;
        }
    }
</style>
